/*! purgecss start ignore */

.react-calendar-heatmap text {
    font-size: 6px;
    font-weight: 500;
    fill: #52575c;
}

.react-calendar-heatmap rect:hover {
    stroke: #2068e3;
}

.react-calendar-heatmap {
    height: 100%;
    width: 100%;
}

/*! purgecss end ignore */
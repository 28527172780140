/*! purgecss start ignore */

.Toastify__toast {
    background-color: white !important;
    padding: 21px 19px !important;
    color: #302d2d;
    position: relative;
}

.Toastify__close-button {
    position: absolute;
    top: 7px;
    right: 12px;
    color: #52575c !important;
}

/*! purgecss end ignore */

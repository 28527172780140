input::-ms-reveal,
input::-ms-clear {
    display: none;
}
.arrow-up-white {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
}

[type='checkbox'],
[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}

.form-input {
    appearance: none;
    background-color: #fff;
    border-color: #d1d5db;
    border-width: 1px;
    border-radius: 0.375rem;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
}

.form-textarea {
    appearance: none;
    background-color: #ffffff;
    border-color: #d2d6dc;
    border-width: 1px;
    border-radius: 0.375rem;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}

.form-checkbox {
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    user-select: none;
    flex-shrink: 0;
    height: 1rem;
    width: 1rem;
    color: #3f83f8;
    background-color: #ffffff;
    border-color: #d2d6dc;
    border-width: 1px;
    border-radius: 0.25rem;
}

.form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.react-tags-wrapper li {
    @apply p-8;
    @apply cursor-pointer;
}


.render-html-normally ol {
    list-style-type: decimal;
}

.render-html-normally ul {
    list-style-type: disc;
}

.render-html-normally ol,
.render-html-normally ul {
    padding-left: 16px;
}

.render-html-normally a {
    @apply underline;
    @apply text-blue-600;
}



/* All above styles are in use as of 7th July 2023 */
/*! purgecss start ignore */
/* For Mentions plugin */
.mentions__suggestions__list {
    box-shadow: 0px 0px 8px rgba(37, 40, 43, 0.12);
    border-radius: 8px;
    max-height: 168px;
    overflow-y: scroll;
}
.mentions--multiLine .mentions__control {
    font-size: 16px;
    line-height: 24px;
}
.mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    min-height: 74px;
    padding: 12px 16px;
}
.mentions--multiLine .mentions__input {
    border: 1px solid #e6e7eb;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 16px;
}

.mentions--multiLine .mentions__input:focus {
    border: 1px solid #2068e3;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: #2c66c9;
    pointer-events: none;
}
/*! purgecss end ignore */

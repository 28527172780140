._pearler-doc h1,
._pearler-doc h2,
._pearler-doc h3,
._pearler-doc h4,
._pearler-doc h5 {
    font-weight: bold;
    margin: 8px 0 4px;
}

._pearler-doc h1 {
    font-size: 28px;
}
._pearler-doc h2 {
    font-size: 24px;
}
._pearler-doc h3 {
    font-size: 20px;
}
._pearler-doc h4 {
    font-size: 18px;
}
._pearler-doc h5 {
    font-size: 16px;
}

._pearler-doc p {
    margin: 8px 0 4px;
}

._pearler-doc ul, ._pearler-doc ol {
    margin-left: 16px;
}

._pearler-doc ul {
    list-style: disc;
}

._pearler-doc ol {
    list-style: decimal;
}

._pearler-doc a {
    color: blue;
    text-decoration: underline;
}

._pearler-doc table {
    /* border: 1px solid #e1dfdf; */
    width: 100%;
    margin: 64px 0 32px;
    position: relative;
}

._pearler-doc tr {
    border: 1px solid #e1dfdf;
}
._pearler-doc td,
._pearler-doc th {
    border: 1px solid #e1dfdf;
    padding: 8px;
}

._pearler-doc td.being-hovered, 
._pearler-doc th.being-hovered,
._pearler-doc td .being-hovered, 
._pearler-doc th .being-hovered,
._pearler-doc p.being-hovered,
._pearler-doc ul.being-hovered,
._pearler-doc ol.being-hovered
 {
    color: green;
    border: 3px dotted green;
    opacity: 1;
}

/* ._pearler-doc table::before {
    content: 'Configure Table';
    padding: 6px 16px 4px;
    background-color: rgba(44, 102, 201, 1.0);
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    position: absolute;
    left: 46%;
    top: -33px;
    opacity: 0.8;
}

._pearler-doc table:hover::before {
    opacity: 1.0;
} */

* {
    @apply antialiased;
}

.h1-large {
    @apply text-44;
    @apply leading-54;
}

.h1-medium {
    @apply text-32;
    @apply leading-40;
}

.h1-small {
    @apply text-26;
    @apply leading-30;
}

.small1-medium {
    @apply font-500;
    @apply text-12;
    @apply leading-16;
}

.h3-bold {
    @apply text-32;
    @apply leading-42;
    @apply font-700;
}

.h4-medium {
    @apply text-26;
    @apply text-center;
    @apply leading-32;
    @apply font-500;
}

.h5-medium {
    @apply text-20;
    @apply leading-28;
    @apply font-500;
}

.h6-medium {
    @apply text-18;
    @apply leading-24;
    @apply font-500;
}

.body-1-reg {
    @apply text-16;
    @apply leading-24;
    @apply font-400;
}

.body-2-reg {
    @apply text-14;
    @apply leading-20;
    @apply font-400;
}

.btn-secondary {
    @apply leading-20;
    @apply text-primary-blue-100;
    @apply bg-white;
    @apply border-primary-blue-100;
    @apply border;
    @apply rounded-md;
    @apply text-14;
    @apply px-23;
    @apply py-9;
    @apply outline-none;
    @apply font-500;
    @apply opacity-80;
    @apply select-none;
}

.subtitle-1-med {
    @apply text-16;
    @apply leading-24;
    @apply font-500;
}

.subtitle-2-reg {
    @apply text-14;
    @apply leading-20;
    @apply font-400;
}

.small-1-med {
    @apply text-12;
    @apply leading-16;
    @apply font-500;
}

.small-1-semibold {
    @apply text-12;
    @apply leading-16;
    @apply font-800;
}

.small-2-reg {
    @apply text-12;
    @apply leading-16;
    @apply font-400;
}

.small-3-reg {
    @apply text-11;
    @apply leading-12;
    @apply font-400;
}

.small-4-reg {
    @apply text-10;
    @apply font-500;
    @apply leading-20;
}

.button-med-14 {
    @apply text-14;
    @apply leading-20;
    @apply font-500;
}

.text-2xl {
    @apply text-24;
    @apply leading-32;
    @apply font-600;
}

:focus {
    outline: none;
}

button:focus {
    outline: none;
}


/* All above styles are in use as of 7th July, 2023 */

/*! purgecss start ignore */

.SingleDatePicker {
    display: block;
    border: 0;
    padding: 0;
}

.SingleDatePickerInput {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.SingleDatePickerInput_calendarIcon {
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 1;
    left: 18px;
}

.DateInput {
    width: 100%;
}

.DateInput_input {
    appearance: none;
    background-color: #ffffff;
    border-radius: 0.375rem;
    padding: 12px 16px;
    font-size: 16px;
    line-height: 24px;
    padding-left: 44px;
    font-weight: 400;
    border: 1px solid #d2d6dc;
    color: #302d2d;
}

.DateInput_input:focus {
    border-color: #2068e3;
}

.DateInput_fang {
    display: none;
}

.CalendarMonth_caption strong {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.CalendarMonth {
    padding: 0;
}

.DayPickerNavigation__horizontal {
    display: flex;
    justify-content: space-between;
    top: 20px;
    padding: 0 14px;
}

.DayPicker__withBorder {
    background: #ffffff;
    border: none;
    box-shadow: 0px 8px 20px rgba(37, 40, 43, 0.08);
    border-radius: 8px;
}

.CalendarDay__default {
    border: none;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: rgba(32, 104, 227, 0.1);
    color: #2068e3;
    border-radius: 8px;
}

.CalendarDay__default:hover {
    background: rgba(32, 104, 227, 0.04);
    color: #2068e3;
    border-radius: 8px;
    border-color: transparent;
}

.DayPicker_weekHeader {
    display: none;
}

.DayPickerKeyboardShortcuts_show {
    display: none;
}

#project-dashboard-due-date-picker,
#project-dashboard-submission-date-picker {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: rgba(82, 87, 92, 1);
}

.dashboard-date-picker-container .SingleDatePickerInput,
.dashboard-date-picker-container .DateInput,
.dashboard-date-picker-container .DateInput_input {
    background-color: transparent;
    background: transparent;
    border: none;
}

.dashboard-date-picker-container .SingleDatePickerInput_calendarIcon {
    left: 0;
}

.dashboard-date-picker-container .DateInput_input {
    padding: 0;
    padding-left: 24px;
}

/*! purgecss end ignore */
